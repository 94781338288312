import Vue from "vue";
import BaseWidget from "../BaseWidget.js";
import App from "./App.vue";
const {
  FROLIC_GAMES,
  PLAY_FROLIC_GAMES,
} = require("../../../../app/common/event_names.js");

const {
  base_url: iframeSrc,
  application_id: applicationId,
  company_id: companyId,
  user,
} = window.__frolic_engage?.conf;

console.log(iframeSrc);
console.log(applicationId);
console.log(companyId);
console.log(user);

const ORIENTATIONS = {
  LANDSCAPE: "Landscape",
  PORTRAIT: "Portrait",
};

// Create a new Vue instance
let app;

class FrolicGames extends BaseWidget {
  constructor(widgetConfig) {
    super();
    this.widgetConfig = widgetConfig;
    this.isRender = false;
    this.state = "closed";
    this.openCount = 0;
    this.softHide = false;
    this.orientation = ORIENTATIONS.PORTRAIT;
  }

  getDiv() {
    if (!this.div) {
      this.div = document.createElement("div");
    }
    return this.div;
  }

  getInnerDiv() {
    if (!this.innerDiv) {
      this.innerDiv = document.createElement("div");
    }
    return this.innerDiv;
  }

  getBaseElement() {
    if (!this.baseelement) {
      this.baseelement = document.getElementsByClassName("baseview");
    }
    return this.baseelement;
  }

  show() {
    let div = this.getDiv();
    let innerDiv = this.getInnerDiv();
    div.className = "modal-overlay";
    innerDiv.className = "modal-content";
    this.state = "open";

    // Destroy the existing Vue instance if it exists
    if (app) {
      app.$destroy();
      app.$el.remove();
      app = null;
    }

    // Create a new Vue instance
    app = new Vue({
      render: (h) => h(App),
    });

    // Mount the new Vue instance
    app.$mount("#frolic-game-widget_");

    const closedGameParentDiv = document.getElementsByClassName(
      this.orientation === ORIENTATIONS.PORTRAIT
        ? "frolic-app"
        : "frolic-app-landscape"
    );
    if (closedGameParentDiv[0]) {
      closedGameParentDiv[0].style.display = "flex";
    }
  }

  hide() {
    let div = this.getDiv();
    let innerDiv = this.getInnerDiv();
    div.className = "modal-overlay-closed";
    innerDiv.className = "modal-content-closed";
    this.state = "closed";

    const closedGameParentDiv = document.getElementsByClassName(
      this.orientation === ORIENTATIONS.PORTRAIT
        ? "frolic-app"
        : "frolic-app-landscape"
    );
    if (closedGameParentDiv[0]) {
      closedGameParentDiv[0].style.display = "none";
    }

    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    if (crossElement) {
      crossElement.style.display = "none";
    }

    // Hard reload by destroying the Vue instance
    if (app) {
      app.$destroy();
      app.$el.remove();
      app = null;
    }

    this.openCount += 1;
  }

  setCrossPositionInLandscape() {
    this.orientation = ORIENTATIONS.LANDSCAPE;
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.classList.remove("portrait-cross");
    crossElement.classList.add("landscape-cross");
  }

  setCrossPositionInPortrait() {
    this.orientation = ORIENTATIONS.PORTRAIT;
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    crossElement.classList.remove("landscape-cross");
    crossElement.classList.add("portrait-cross");
  }

  logohide() {
    const containerDiv = document.querySelector(".container-div_game_settings");
    const mainDiv = document.querySelector(".main-div_game_settings");
    const slotMachineBox = document.querySelector(".modal-overlay-closed");
    if (slotMachineBox) {
      slotMachineBox.remove();
    }
    if (containerDiv) {
      containerDiv.remove();
    }
    if (mainDiv) {
      mainDiv.remove();
    }
    this.isRender = false;
  }

  init(callback) {
    if (this.isRender === false) {
      this.render(document.body, document.head, callback);
    } else {
      callback(this);
    }
  }

  handlePostmessage = (event) => {
    if (event && event?.data?.type === "FrolicHideWidget") {
      this.hide();
    }
    if (event && event?.data?.type === "FrolicLandscapeCross") {
      this.setCrossPositionInLandscape();
    }
    if (event && event?.data?.type === "FrolicPortraitCross") {
      this.setCrossPositionInPortrait();
    }
  };

  handleGameOpen = (event) => {
    if (event && event?.data?.type === "FrolicOpenWidget") {
      this.show();
      this.fetchData = true;
    }
  };

  gameGetWidgetComponent() {
    const stylesheet = document.createElement("style");
    stylesheet.textContent = `
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modal-overlay-closed {
        display: none;
      }

      .modal-content {
        background: white;
        width: 80%;
        max-width: 600px;
        height: 80%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modal-content-closed {
        display: none;
      }

      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 101;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      .close-btn img {
        height: 24px;
        width: 24px;
      }
    `;

    const mainDiv = document.createElement("div");
    mainDiv.className = "main-div_game_settings";
    const div = this.getDiv();
    div.className = "modal-overlay-closed";

    const appContainer = document.createElement("div");
    appContainer.id = "frolic-game-widget_";
    appContainer.className = "modal-content-closed";

    const closeButton = document.createElement("button");
    closeButton.className = "close-btn";
    const closeImg = document.createElement("img");
    closeImg.src =
      "https://cdn.pixelbin.io/v2/frolic/original/__playground/close.png"; // Original cross image
    closeButton.appendChild(closeImg);
    closeButton.addEventListener("click", () => this.hide());

    // Append the close button to the div, not the appContainer
    div.appendChild(closeButton);

    return {
      stylesheet,
      widget: appContainer,
      innerDiv: appContainer,
      div,
    };
  }

  render(bodyElement, headElement, callback) {
    const { stylesheet, widget, innerDiv, div } = this.gameGetWidgetComponent();
    headElement.append(stylesheet);
    bodyElement.append(widget);
    div.append(innerDiv);
    bodyElement.append(div);
    this.isRender = true;

    // Do not open the game upon rendering, keep it hidden initially
    callback(this);
  }
}

const frolicGames = new FrolicGames(
  window.__frolic_engage.getWidgetConfig(FROLIC_GAMES)
);
window.__frolic_engage.registerWidget(FROLIC_GAMES, frolicGames);

window.addEventListener("message", frolicGames.handlePostmessage);
window.addEventListener("message", frolicGames.handleGameOpen);

window.addEventListener(PLAY_FROLIC_GAMES, function () {
  console.log(`${PLAY_FROLIC_GAMES} event received`);
  frolicGames.show();
});
